<!-- 裱花工序 -->
<template>
    <div class="wrap">
      <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
      <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </div>
  </template>
  <script>
  import TablePage from "@/components/tablePage";
  import Dialog from "@/components/Dialog";
  import { listProcess, delProcess, decorationProcess } from "@/api/decoration/base/process.js";
  
  export default {
    name: "Process",
    components: { TablePage, Dialog },
    data() {
      return {
        dialogOptions: {
          title: "",
          width: 620,
          show: false,
          type: "Process",
          formData: null,
        },
        options: {
          mutiSelect: true, // 多选
          loading: true,
          pagination: {
            total: 0,
            page: 1,
            size: 15,
          },
          defaultBody: { delFlag: 0 },
          getListApi: listProcess,
          body: {},
          listNo: true, // 序号
          title: "裱花工序管理",
          check: [], // 选中数据
          rowKey: "processId",
          search: [
            {
              type: "input",
              tip: "工序编号/工序名称",
              model: "",
              filter: "query",
            },
            { type: "button", tip: "查询", btnType: "primary", click: "search" },
          ],
          buttons: [
            {
              click: "add",
              label: "新增",
              icon: "el-icon-plus",
              type: "primary",
            },
            {
              click: "del",
              alertText: "确认要删除选中工序吗？",
              label: "删除",
              type: "danger",
              btnType: "dropdown",
              other: [
                {
                  click: "delRecovery",
                  label: "回收站",
                  multiple:false,
                },
              ],
              icon: "el-icon-delete",
              // disabled: () => !this.options?.check?.length,
            },
            {
              click: "refresh",
              right: true,
              label: "刷新",
              icon: "el-icon-refresh",
              type: "",
            },
          ],
          columns: [
            {
              prop: "processNo",
              label: "工序编号",
              type: "link",
              click: "update",
              minWidth: 110,
            },
            {
              prop: "processName",
              label: "工序名称",
              minWidth: 110,
            },
            {
              prop: "isFinish",
              label: "是否结束工序",
              formatter: (v, row) => row.isFinish ? '是': '否',
              minWidth: 100,
            },
            {
              prop: "sortNo",
              label: "排序",
              minWidth: 100,
            },
            {
              prop: "createBy",
              label: "创建人",
              minWidth: 80,
            },
            {
              prop: "createTime",
              label: "创建时间",
              minWidth: 150,
            },
            {
              prop: "updateBy",
              label: "修改人",
              minWidth: 80,
            },
            {
              prop: "updateTime",
              label: "修改时间",
              minWidth: 150,
            },
          ],
          list: [],
        },
      };
    },
    methods: {
      async handleEvent(type, row) {
        switch (type) {
          case "dialogChange":
            this.$refs.tablePage.getList();
            break;
          case "update":
            {
              const options = {
                show: true,
                title: "编辑工序",
                click: type,
                data: {
                  type: 'decorationProcess'
                },
                formData: row,
              };
              this.dialogOptions = {
                title: "",
                width: 620,
                show: false,
                type: "Process",
                formData: null,
              }
              this.dialogOptions = { ...this.dialogOptions, ...options };
            }
            break;
          case "add":
            {
              const options = {
                show: true,
                title: "新增工序",
                click: type,
                data: {
                  type: 'decorationRoom'
                },
                formData: {},
              };
              this.dialogOptions = {
                title: "",
                width: 620,
                show: false,
                type: "Process",
                formData: null,
              }
              this.dialogOptions = { ...this.dialogOptions, ...options };
            }
            break;
          case "del": // 选中数据 Array
            try {
              await delProcess(this.options.check.map((x) => x.processId));
              this.$message.success("删除成功！");
              this.$refs.tablePage.getList();
            } catch (error) {}
            break;
          case "delRecovery":
            this.dialogOptions = {
              type: "ShopInfo",
              show: true,
              title: "回收站",
              width: "75vw",
              data: {
                getListApi: listProcess,
                restoreListApi: decorationProcess,
                type: 'decorationRoom',
                id: "processId",
                search: [
                  {
                    type: "filters",
                    isRestore: true,
                    model: "",
                    filters: [
                      { filter: "query", label: "全部" },
                      { filter: "processNo", label: "工序编号" },
                      { filter: "processName", label: "工序名称" },
                    ],
                  },
                  {
                    type: "button",
                    tip: "查询",
                    btnType: "primary",
                    click: "search",
                    isRestore: true,
                  },
                ],
                columns: [
                  {
                    prop: "processNo",
                    label: "工序编号",
                    minWidth: 180,
                  },
                  {
                    prop: "processName",
                    label: "工序名称",
                    minWidth: 180,
                  },
                  {
                    prop: "updateBy",
                    label: "修改人",
                    minWidth: 120,
                  },
                  {
                    prop: "updateTime",
                    label: "修改时间",
                    minWidth: 155,
                  },
                  {
                    prop: "createBy",
                    label: "创建人",
                    minWidth: 120,
                  },
                  {
                    prop: "createTime",
                    label: "创建时间",
                    minWidth: 155,
                  },
                ],
              },
            }
            break;
          default:
            break;
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped></style>
  